import {Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren} from '@angular/core';
import {IGroupSettings} from '../group-settings';
import {MatSort} from '@angular/material/sort';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {IEndpoint} from '../../../../types/subscription';
import {HeaderService} from '../../../header.service';
import {D3eService} from '../../../../services/d3e-service';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {IPolicyListItem} from '../../../../types/d3ePolicy';
import {LicenseMethods} from '../../../../shared/license.methods';
import {UniqueGroupNameValidator} from '../../../shared/validators/custom-validators';
import {TranslateService} from '@ngx-translate/core';
import {IEndpointsResponse} from '../../../../types/endpoints';
import {ALL_ORGS_ID} from '../../../../types/group';

interface ISortableDevice extends IEndpoint {
  selected: boolean;
  checkboxTooltip: string;
}

@Component({
  selector: 'app-group-details-device',
  templateUrl: './group-details-device.component.html',
  styleUrls: ['./group-details-device.component.scss']
})
export class GroupDetailsDeviceComponent implements OnChanges {
  search: string = '';
  selectedIds: Map<number, boolean> = new Map<number, boolean>();
  loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input() groupSettings: IGroupSettings;
  // @Input() errors: boolean;
  @Input() groupNameValidator: UniqueGroupNameValidator;
  @Output() groupSettingsChange: EventEmitter<IGroupSettings> = new EventEmitter<IGroupSettings>();
  // @Output() errorsChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  form: UntypedFormGroup;
  dataSource: MatTableDataSource<ISortableDevice> = new MatTableDataSource<ISortableDevice>();
  columns = ['select', 'name', 'os', 'last_logged_in_user'];
  selection: SelectionModel<ISortableDevice> = new SelectionModel<ISortableDevice>(true);
  policies: IPolicyListItem[];
  countDisplay = '';

  formSkeletonTheme = {
    height: '40px',
    width: '25em',
    margin: '10px',
    padding: '0'
  };

  tableSkeletonTheme = {
    height: '40px',
    width: '90%',
    margin: '10px',
    padding: '0'
  };

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly d3eSvc: D3eService,
    private readonly headerSvc: HeaderService,
    public readonly translate: TranslateService,
  ) {
    this.form = fb.group({
      name: ['', Validators.required],
      description: [''],
      policy: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const settingsFirstChange = changes['groupSettings']?.firstChange;
    const settingsHasChanged = changes['groupSettings']?.currentValue !== changes['groupSettings']?.previousValue;
    if (settingsFirstChange && settingsHasChanged) {
      let observables: [Observable<IEndpointsResponse>, Observable<IPolicyListItem[]>];
      observables = [
        this.searchDevices(this.headerSvc.orgSelectedChanged.value),
        this.getPolicies(this.headerSvc.orgSelectedChanged.value),
      ];
      forkJoin(observables).subscribe({
        next: ([devices, policies]) => {
          this.selectedIds.clear();
          this.selection.clear();
          changes.groupSettings.currentValue.members.forEach(deviceId => this.selectedIds.set(deviceId, true));
          this.setPolicies(policies);
          this.setDevices(devices);
          this.dataSource.sortingDataAccessor = (device: ISortableDevice, sortHeaderId: string): string | number => {
            switch (sortHeaderId) {
              case "select":
                return (device.selected) ? 1 : 2;
              case "name":
                return device.computer_name.toLowerCase();
              case "last_logged_in_user":
                return device.last_logged_in_user?.email;
              default:
                if (typeof device[sortHeaderId] === 'string') {
                  return device[sortHeaderId].toLowerCase();
                }
                return device[sortHeaderId];
            }
          };
          this.form.setValue({
            name: changes.groupSettings.currentValue.name,
            description: changes.groupSettings.currentValue.description,
            policy: changes.groupSettings.currentValue.policyId || -1,
          });
          this.loaded$.next(true);
        },
        error: error => {
          console.error(error);
          this.loaded$.next(true);
        }
      });
    }

    if (changes?.groupNameValidator && changes?.groupNameValidator?.currentValue) {
      this.form.controls.name.setAsyncValidators([changes.groupNameValidator.currentValue?.validate.bind(this.groupNameValidator)]);
    }
  }

  searchDevices(selectedOrgId: string): Observable<IEndpointsResponse> {
    const query = {
      search: this.search,
      include_group_info: true,
      selectedOrgId: selectedOrgId == ALL_ORGS_ID ? undefined : selectedOrgId,
    };
    return this.d3eSvc.get_home_devices(query);
  }

  getDevices() {
    this.searchDevices(this.headerSvc.orgSelectedChanged.value).subscribe(this.setDevices.bind(this));
  }

  setDevices(response: IEndpointsResponse) {
    this.selection.clear();
    this.dataSource.sort = this.sort.toArray()[0];
    const devices = response.devices.sort((a,b) => a.computer_name.localeCompare(b.computer_name)) as ISortableDevice[];
    this.dataSource.data = devices.map((d: ISortableDevice) => {
      d.os = LicenseMethods.getOsVersion(d);
      d.selected = false;
      d.checkboxTooltip = ((<IEndpoint>d).device_set_id > -1) ? this.translate.instant(
        'groups.device-already-assigned', {group: d.device_set_name}) : '';
      return d;
    });
    for (const device of devices as ISortableDevice[]) {
      if (!this.selectedIds.has(device.id)) {
        this.selectedIds.set(device.id, false);
        device.selected = false;
      }
      if (this.selectedIds.get(device.id)) {
        this.selection.select(device);
        device.selected = true;
      } else {
        device.selected = false;
      }
    }
    this.resort();
    if ( response.devices.length < response.total ) {
      this.countDisplay = response.devices.length + ' of ' + response.total;
    } else {
      this.countDisplay = '';
    }
  }

  getPolicies(selectedOrgId: string): Observable<IPolicyListItem[]> {
    return this.d3eSvc.getD3EPolicies(selectedOrgId == ALL_ORGS_ID ? undefined : selectedOrgId);
  }

  setPolicies(policies: IPolicyListItem[]) {
    let lowestPriorityPolicy: IPolicyListItem = null;
    this.policies = policies.sort((a: IPolicyListItem, b: IPolicyListItem) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      } else if (b.name.toLowerCase() > a.name.toLowerCase()) {
        return -1;
      }
      return 0;
    });
    const value = this.form.controls['policy'].value
    if ( value == null || value == '' ) {
      // set the policy to the default policy
      this.policies.forEach(policy => {
        if (lowestPriorityPolicy == null) {
          lowestPriorityPolicy = policy;
        } else {
          if (policy.priority < lowestPriorityPolicy.priority) {
            lowestPriorityPolicy = policy;
          }
        }
      });
      if (lowestPriorityPolicy != null && (value == '' || value == null)) {
        this.form.controls['policy'].setValue(lowestPriorityPolicy.id);
      }
    }
  }

  emitChanges() {
    const members: number[] = [];
    for (const licenseId of this.selectedIds.keys()) {
      if (this.selectedIds.get(licenseId)) {
        members.push(licenseId);
      }
    }
    const settings: IGroupSettings = {
      groupId: this.groupSettings.groupId,
      name: this.form.value.name,
      description: this.form.value.description,
      policyId: this.form.value.policy,
      members: members,
    };
    this.groupSettingsChange.emit(settings);
    // this.errorsChange.emit(!!this.form.errors);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data
        .filter((d: ISortableDevice) =>
          d.device_set_id === this.groupSettings.groupId || d.device_set_id === -1
        ).length;
    // This is >= and not === to allow the deselection of an endpoint that is in a disabled row.
    // Meaning it is also in another group, which we do not want.
    return numSelected >= numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.dataSource.data.forEach((device: ISortableDevice) => {
        this.selectedIds.set(device.id, false);
        device.selected = false;
      });
    } else {
      this.dataSource.data.forEach((device: ISortableDevice) => {
        // We don't want to select endpoints in other groups, but if we find an endpoint that is selected
        // and in another group we deselect the endpoint from this group (in the else) even though
        // we are doing a select all.
        if (device.device_set_id == this.groupSettings.groupId || device.device_set_id == -1) {
          this.selection.select(device);
          this.selectedIds.set(device.id, true);
          device.selected = true;
        } else {
          this.selectedIds.set(device.id, false);
          device.selected = false;
        }
      });
    }

  }

  toggleAll() {
      this.masterToggle();
      this.emitChanges();
  }

  toggleRow(toggle: boolean, row: ISortableDevice) {
    if (toggle && row.device_set_id !== this.groupSettings.groupId && row.device_set_id !== -1) {
      return;
    }
    if (toggle) {
      this.selection.select(row);
    } else {
      this.selection.deselect(row);
    }
    this.selectedIds.set(row.id, toggle);
    row.selected = toggle;
    this.resort();
    this.emitChanges();

  }

  resort() {
    const sort = this.sort.toArray()[0];
    sort.sortChange.emit({active: sort.active, direction: sort.direction});
  }
}
